import React from "react"
import Layout from "../../Layout"
import Meta from "../../components/Meta"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import Spacer from "../../components/Spacer"
import StaticHeadline from "../../components/static/StaticHeadline"
import StaticParagraph from "../../components/static/StaticParagraph"
import StaticContainer from "../../components/static/StaticContainer"
import StaticImage from "../../components/static/StaticImage"

import usePagesImages from "../../hooks/usePagesImages"

export default function BenzinpreisVerlaufEntwicklung({ data }) {
  const statista = usePagesImages("benzinpreis-verlauf-entwicklung-2.jpg")
  const bundesamt = usePagesImages("benzinpreis-verlauf-entwicklung-3.jpg")
  const adac = usePagesImages("benzinpreis-verlauf-entwicklung-4.jpg")

  return (
    <Layout>
      <Meta
        title="Spritpreise: Benzinpreis-Verlauf &amp; Entwicklung "
        description="Wie hat sich der Benzinpreis-Verlauf in den letzten Jahrzehnten verändert? Und wie entwickeln sich die Spritpreise im Tagesverlauf? Tankchecker informiert!"
        image={statista.src}
      />
      <Header />
      <main>
        <StaticContainer maxWidth="lg">
          <Spacer />
          <StaticHeadline variant="h1" align="center" gutterBottom>
            Spritpreise: Durchschnittlicher Benzinpreis-Verlauf und Entwicklung
          </StaticHeadline>
          <StaticImage
            fluid={statista}
            alt="Preisentwicklung ausgewählter OPEC-Rohöle in den Jahren 1960 bis 2021 (in US-Dollar je Barrel)"
            copyright="Bildnachweis: Statista"
          />
          <StaticParagraph gutterBottom>
            Die Spritpreise haben sich über die letzten Jahrzehnte
            kontinuierlich erhöht. Allein in den letzten knapp 20 Jahren – also
            seit der Einführung des Euros Anfang 2002 – stieg der Benzinpreis im
            Schnitt um 30 Cent an. Globale (Wirtschafts-)Krisen, Kriege oder
            wirtschaftspolitische Ereignisse spiegeln sich auch immer im Verlauf
            des Benzinpreises wider. Ölkrisen, der Golfkrieg Anfang der
            80er-Jahre oder die Weltwirtschaftskrise 2008/2009: Sie alle hatten
            Einfluss auf die Ölpreise und damit auch auf die Spritpreise an den
            Tankstellen. Wenn in einem der ölfördernden oder -produzierenden
            Länder die Menge der Ölförderung zurückgeht, steigt durch die
            Rohstoffmengenverknappung natürlich der Handelspreis des Rohöls an
            der Börse in Rotterdam:
          </StaticParagraph>

          <StaticParagraph gutterBottom>
            Deutschland ist eines der teuersten Länder in Europa, wenn es um den
            Benzinpreis geht. Über zwei Drittel des Literpreises sind in der
            Bundesrepublik Steuerabgaben. Die Spritpreise zogen beispielsweise
            noch einmal an mit der Einführung der Ökosteuer, die zwischen 1999
            und 2003 jährlich erhöht wurden) oder jetzt ganz aktuell mit
            Einführung der CO2-Steuer, die seit 2021 mit in dem
            Steuerabgabepaket enthalten ist. Auch diese neue Steuer wird in den
            nächsten Jahren sukzessive angehoben, sodass mit einer
            Benzinpreis-Erhöhung zum Jahresbeginn jeweils zu rechnen ist.
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Der Benzinpreis-Verlauf in den Jahren 2020 und 2021
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            2020 war die Weltwirtschaft geprägt von der sich rasch verbreitenden
            Corona-Pandemie. Die Folge war ein deutlicher Wirtschaftsrückgang,
            Läden mussten immer wieder schließen, es gab Lockdowns und Shutdowns
            und wo es möglich war, auch Homeoffice statt Fahrten zur
            Arbeitsstätte. Infolgedessen sank der Benzinpreis im Laufe des
            Jahres 2020 am Beispiel von Super E10 von durchschnittlich 1,40
            Euro/Liter im Januar 2020 auf 1,23 Euro/Liter im Dezember 2020. Seit
            Jahresbeginn steigen nun wieder die Preise. Verantwortlich dafür ist
            zum einen die Einführung der CO2-Steuer, die direkt zum Jahresbeginn
            2021 die Preise um ca. 7 Cent/Liter anhob. Mit Beginn des Jahres
            2021 wurde außerdem die temporäre Senkung der Mehrwertsteuer wieder
            auf den alten Satz von 19 % angehoben. Zudem ist durch die
            zunehmenden Impfungen die Hoffnung groß, dass sich die
            Weltwirtschaft langsam, aber sicher wieder erholen wird vom
            Corona-Tief des Vorjahres. Und so stieg schon jetzt der Rohölpreis
            wieder an und damit auch die Kraftstoffpreise an den Tankstellen.
            Diverse Extremwetterphänomene ließen in den USA die Ölförderung
            stocken. Sinkende Fördermengen bedeuten eine Verknappung der
            Ölmengen und damit natürlich einen Anstieg des Verkaufspreises, der
            sich im Benzinpreis an der Tankstelle niederschlägt.
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Wie entwickelt sich der Benzinpreis im Tagesverlauf?
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Schaut man sich die Statistiken über die Benzinpreise im
            Tagesverlauf an, so erkennt man durchaus eine Art Wellenbewegung
            über den Tag verteilt. Die Spritpreise steigen und sinken mehrmals
            am Tag:
          </StaticParagraph>
          <StaticImage
            fluid={bundesamt}
            alt="Kraftstoffpreise Deutschland 2019 - Preisunterschiede im Tagesverlauf"
            copyright="Bildnachweis: Bundeskartellamt/ Markttransparenzstelle für Kraftstoffe"
          />
          <StaticParagraph gutterBottom>
            Auffällig ist dabei, dass der Verlauf des Benzinpreises insgesamt
            über den Tag gesehen sinkt. Am günstigsten tankst Du also abends
            zwischen 19 und 22 Uhr, wohingegen die ungünstigste Zeit morgens
            zwischen 5 und 9 Uhr liegt. Entweder, Du versuchst mithilfe einer
            Tank-App oder online die besagten Preisspitzen und -täler im Auge zu
            behalten und möglichst spät am Tag zu einer Zeit zu tanken, wo sich
            der Benzinpreis gerade weiter Richtung Preistal befindet. Oder Du
            machst Dich direkt die Mühe und tankst möglichst erst gegen Abend,
            wenn die Chance auf den billigsten Spritpreis des Tages am höchsten
            ist.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Untersuchungen des ADAC im Jahr 2020 zeigen, dass es mittlerweile
            bis zu 6 Preisspitzen pro Tag gibt. Der Gedanke dahinter ist simpel:
            Mit vielen Preissprüngen versuchen die Mineralölkonzerne höhere
            Gewinne zu erzielen. Die Beobachtungen zeigten, dass selbst abends
            nun noch einmal mit einer kleinen Erhöhung für kurze Zeit gerechnet
            werden kann.
          </StaticParagraph>
          <StaticImage
            fluid={adac}
            alt="So ändert sich der Spritpreis im Tagesverlauf"
            copyright="Bildnachweis: ADAC e.V."
          />
          <StaticHeadline variant="h2" gutterBottom>
            Die günstigen Benzinpreise: Welcher ist der günstigste Wochentag?
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Viele Apps und Vergleichsseiten bieten Dir als Nutzer die
            Möglichkeit, bei der jeweiligen Tankstelle sich die Spritpreise
            nicht nur im Tagesverlauf, sondern auch im Wochenverlauf anzeigen zu
            lassen. Das macht Sinn, wenn Du herausfinden möchtest, ob es einen
            besonders günstigen Wochentag womöglich gibt, an dem sich das Tanken
            noch mehr lohnt als an den anderen Tagen.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Früher gab es die klare Empfehlung, nicht an einem Freitagabend zu
            tanken, weil die Preise zum Wochenende hin wieder anzogen. Diese
            Aussage trifft heute nicht mehr wirklich zu. Es kann natürlich nie
            schaden, sich den Benzinpreis-Verlauf der vergangenen Woche(n)
            anzusehen. Das wahrscheinlich größere Einsparpotenzial hast Du aber
            wohl eher mit der richtigen Uhrzeit. Hier lassen sich durchaus bis
            zu 10 Cent/Liter einsparen, wenn man den teuersten Tagesspritpreis
            mit dem günstigsten Benzinpreis vergleicht.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Es kann allerdings sein, dass Du Unterschiede in den Preisen der
            Wochentage feststellst. Hier gilt das Prinzip von Angebot und
            Nachfrage: Wenn aus irgendwelchen Gründen die Nachfrage an den
            Tankstellen an einem Tag besonders hoch ist, reagieren natürlich die
            Mineralölkonzerne und erhöhen möglicherweise die Preise an den
            Tankstellen. So kann es sein, dass an einem Samstag, wenn viele ihr
            Auto durch die Waschanlage fahren und dann gleich auftanken wollen,
            die Preise tagsüber durchaus höher liegen als an einem Wochentag,
            wenn nicht so viele Menschen tagsüber die Tankstelle frequentieren.
            Hier lohnt es sich also durchaus, in der App den Verlauf der
            Benzinpreise sowohl im Tagesverlauf als auch im Wochenverlauf zu
            vergleichen, um möglichst günstig zu tanken.
          </StaticParagraph>

          <Spacer />
          <Spacer />
        </StaticContainer>
      </main>
      <Footer />
    </Layout>
  )
}
